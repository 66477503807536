<template>
	<div class="mask-page">
		<div class="main" :style="mainBg">
			<img class="tips-img" src="@/assets/images/blindbox/title_03.png" alt="">
			<div class="item-list">
				<van-list v-model="loading" :finished="finished" :immediate-check ='false' finished-text="暂无更多抽奖记录" @load="onLoad">
					<van-cell v-for="(item, index) in recordList" :key="index">
						<div class="item">
							<p>{{item.createTime}} 抽奖获得</p>
							<div class="img-box">
								<img :src="imgHost+item.prizeUrl" alt="">
							</div>
						</div>
					</van-cell>
				</van-list>
			</div>
			<div @click="goBack()" class="btn-box">
				<img src="@/assets/images/blindbox/btn_02.png" alt="">
			</div>
		</div>
	</div>
</template>

<script>
	import { recordListApi } from '@/request/api'
	export default {
		data() {
			return {
				mainBg: {
					backgroundImage: `url(${require("@/assets/images/blindbox/gift-bj.png")})`,
					backgroundSize: '100%, 100%'
				},
				loading: false,
				finished: false,
				formData: {
					lotteryId: '',
					propsId: '',
					current: 1,
					size: 11
				},
				recordList: [],
				total: 0,
				imgHost: process.env.VUE_APP_IMG_URL
			}
		},
		methods: {
			onLoad() {
				recordListApi(this.formData).then(res => {
					if (this.recordList.length === res.data.total) {
						this.finished = true
						return
					}
					this.formData.current ++
					res.data.records.map(item => {
						this.recordList.push(item)
						return this.recordList
					})
					console.log(this.recordList)
					this.loading = false
				}).catch(err => {
					this.finished = true
					this.loading = false
					this.$toast(err.message)
				})
			},
			goBack() {
				this.$router.back()
			}
		},
		mounted() {
			this.formData.lotteryId = this.$route.query.lotteryId
			this.formData.propsId = this.$route.query.propsId
			this.onLoad()
		}
	}
</script>

<style lang="stylus" scoped>
.mask-page
	position absolute
	top 0
	left 0
	display flex
	flex-direction column
	justify-content center
	align-items center
	padding 0 0.746666rem
	box-sizing border-box
	width 100%
	height 100%
	.main
		position relative
		width 100%
		height 14.4rem
		.tips-img 
			position absolute
			left -0.533333rem
			top 2px
			width 6.56rem
			height 1.74rem
		.item-list 
			margin 2rem 0.8rem 0
			height 10.3rem
			overflow-y scroll
			.van-cell
				padding 0
				background none
				.item
					display flex
					justify-content space-between
					align-items center
					margin 0.05rem 0
					p
						font-size 0.426666rem
						color #5A4217
					.img-box
						box-sizing border-box
						padding 0.05rem
						width 0.933333rem
						height 0.933333rem
						border 1px solid #B5A585
						background #E3DAC4
						img 
							width 100%
							height 100%
			.van-cell::after
				border none
		.item-list::-webkit-scrollbar
			display none
		.btn-box 
			position absolute
			bottom 0.266666rem
			left 50%
			transform translate(-50%, 0)
			font-size 0
			img 
				width 5.333333rem
				height 1.546666rem
</style>
